/* You can add global styles to this file, and also import other style files */
@import "theme/variables";
@import 'theme/bootstrap/bootstrap-custom-grid';

html, body { 
    height: 100%;
    overflow-x: hidden;
}

a{
    color: #C67EFF;
    text-decoration: none;
}

h2 {
    color: #fff;
}

body { 
    font-family: "Space Grotesk"; 
    background-image: linear-gradient(rgba(20, 20, 20, 0.9), rgba(20, 20, 20, 0.9)),url("../src/assets/background.png") !important;
    // background-image: url("../src/assets/background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 0 auto;
}

h1{
    color: #fff;
    margin-left: 5px;    
    margin-top: 0;
    padding-top: 20px;
}

// section {
//     max-width: 1200px;
//     margin: 0 auto 70px auto;
//     background: rgba(0, 0, 0, 0.85);
// }

.header_container {
    max-width: 1200px;
    margin: 0 auto;

    .logo_wrap {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .relativ_Header{
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;
        top: 111px;
    }

    .header_wrap {
        margin-top: 44px;
    }

    .header_center_part {
        display: flex;
    }

    .mat-icon {
        color: #fff;
        cursor: pointer;
        position: relative;
        border: 1px solid;
        padding: 25px;
        border-radius: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.home_container input {
    border: none;
    color: #6e6e6e;
    font-size: 16px;
    padding-left: 20px;
    border-radius: 56px;
}

app-startpage.ng-star-inserted {
    position: relative;
}

// button.mat-raised-button.mat-button-base {
//     // background: linear-gradient(to right, #ebaf54 0%,#ece185 49%,#e4af18 100%);
//     // width: 100%;
//     // padding: 3px 0;
//     // margin-bottom: 35px;
// }

// .home_container {
//     width: 100%;
//     margin: 0 auto;
//     padding: 20px 0;
//     max-width: 1200px;
//     background: rgba(0, 0, 0, 0.85);
// }

.search_container {
    max-width: 1200px;
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
    min-height: calc(100vh - 280px);
    height: 100%;

    h2{
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        line-height: 26.4px;
        color: #fff ;
        margin-bottom: 0;
    }

    h1{
        text-align: center;
        font-size: 48px;
        font-weight: 700;
        line-height: 52.8px;
        color: #fff;
        letter-spacing: -4%;
    }
    p{
        text-align: left;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        color: #fff ;
    }
}

.home_container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    min-height: calc(100vh - 280px);
    height: 100%;

    h1 {
        text-align: center;
        font-size: 72px;
        font-weight: 700;
        line-height: 97.2px;
        letter-spacing: -4%;
    }
    p{
        text-align: center;
    }

    .home_middle_wrap {        
        form {
            display: flex;
            justify-content: center;
        }

        .mat-form-field{
            background: #ccc;
            margin: 20px 0;
            background: #fff;
            margin: 20px 0;
            height: 72px;
            max-width: 570px;
            width: 100%;
            border: 1px solid #000000;
            border-radius: 56px;
        }
    }
}

mat-form-field {
    width: 100%;
    background: #fff;
    margin: 9px 0;
}

span.mat-form-field-label-wrapper{
    text-align: center;
}

.mat-form-field-underline {
    display: none;
}


// .home_wrapper {
//     display: flex;
//     justify-content: center;
// }


.search_wrapper {
    margin-top: 15px;
    display: flex;
    justify-content: center;
}

.divider {
    margin: 40px auto;
    width: 98%;
    height: 1px;
    border-bottom: 2px dotted #edcb78;
    position: relative;
}

.divider_img {
    position: absolute;
    width: 50px;
    left: 50%;
    margin-left: -25px;
    margin-top: -15px;
    text-align: center;
}

.button_filter {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.back_button_wrap {
    .back_button{
        position: absolute;
        left: 30px;
    }
}

img{
    width: 100%;
    height: auto;
}

.search_button{
    display: flex;
    justify-content: center;

    .back_button{
        position: absolute;
        right: 30px;
    }
}

.aroundme_button{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

p {
    color: #fff;
}

mat-list-item.mat-list-item {
    background: #898989;
    color: #d2d2d2 !important;
}

.search_list p {
    color: #000;
    margin: 0px;
}

.mat-icon {
    position: absolute;
    right: 0;
}

#map {
    width: 100%;
    height: 500px;
}

#tooltip {
    position: absolute; 
    background: white;
    margin: 10px; padding: 10px;
    opacity: 0;
}

mat-list-item.mat-list-item {
    height: 100% !important;
    margin: 1px 0;
    padding: 5px 0 !important;
}

mat-card.mat-card {
    background: #898989;
    color: #d2d2d2;
    border-radius: inherit;
}

mat-slide-toggle#mat-slide-toggle-1 {
    margin-bottom: 15px;
}

span.mat-slide-toggle-content {
    color: #fff;
}

.mat-slide-toggle {
    margin-left: 20px;
}

.mat-slide-toggle-bar{
    background: #d2d2d2;
}

.footer {
    // padding: 60px 0;
    // max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    position: relative;
    bottom: -75px;
    display: flex;
    justify-content: center;

    .footer_arrow{    
        width: 40px;
        margin: 0 8px;
    }

    ul {
        list-style: none;
        padding: 0;
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        justify-content: center;

        li {
            margin: 5px;
        }
    }

    .footer_container {
        text-align: center;

        a{
            color: rgba(255, 255, 255, 0.7);;
            text-decoration: none;
            font-size: 13px;
            font-weight: 400;
            line-height: 16.5px;

        }
    }

    .footer_hall_create {
        p {
            font-size: 16px;
            line-height: 17.6px;
            font-weight: 400;
        }

        a{
            color: #C67EFF;
            font-size: 16px;
            line-height: 17.6px;
            font-weight: 400;
        }
    }
}

.hidden_desktop{
    display: none;
}

.hidden{
    display: none;
}

.hall_list{
    border-top: 1px solid #FFFFFF33;
    h1 {
        font-size: 20px;
        line-height: 30px;
        font-weight: 700;
        margin: 0;
    }
}

.hall_list:last-child{
    border-bottom: 1px solid #FFFFFF33;
}

.search_nav_container {
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 45px;
    height: auto;
    margin: 0 auto;
    left: 0;
}

mat-form-field.mat-form-field {
    background: #ccc;
    margin-bottom: 10px !important;
    background: #fff;
    height: 72px;
    width: 100%;
    border: 1px solid #000000;
    border-radius: 56px;
    align-items: center;
    margin: 0 auto;
}
.header_search{
    transition: max-height 0.3s ease-in;
    background-image: linear-gradient(rgba(20, 20, 20, 0.9), rgba(20, 20, 20, 0.9)),url("../src/assets/background.png") !important;
    background-position: center;
    z-index: 1;
    width: 100%;
    max-width: 1200px;
    height: auto;
    padding: 0px 15px 15px 15px;
    margin: 0 auto;

    form{
        display: flex;
    }

    mat-form-field.mat-form-field {
        background: #ccc;
        margin: 20px 0;
        background: #fff;
        height: 72px;
        max-width: 570px;
        width: 100%;
        border: 1px solid #000000;
        border-radius: 56px;
        align-items: center;
        margin: 0 auto;
        justify-content: center;
    }

    p {
        text-align: center;
        padding-top: 15px;
    }

    input{
        margin-left: 0px;
        width: 100%;
        line-height: 15px;
        border: none;
        color: #6e6e6e;
        font-size: 14px;
        border-radius: 0;
        margin-right: 5px;
    }
}

.operator-toggle{
    max-width: none !important;
    white-space: initial !important;
    height: 50px !important;

    .mat-slide-toggle-content {
        white-space: inherit !important;
    }

    p {
        text-align: left;
    }
}

.detail_container {
    max-width: 1200px;
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
    min-height: calc(100vh - 280px);
    height: 100%;

    h1{
        text-align: center;
        text-transform: uppercase;
    }

    .detail_info {
        display: flex;
        align-items: center;
        justify-content: center;

        .automaten_stellplatz, .wettbuero, .spielhalle {
            margin-right: 8px;
            margin-top: 12px;
        }

        p{
            margin-right: 8px;  
        }

    }

    button{
        margin-top: 30px;
    }

    .mat-accordion{
        margin-bottom: 30px;
    }

    .mat-expansion-panel {
        background: transparent;
        border-top: 1px solid #e4af18;
        border-bottom: 1px solid #e4af18;
        margin-bottom: 30px;
        border-radius: 0px !important;
    }

    .mat-expansion-panel-header-title, .mat-expansion-panel-header-description{
        color: #fff;
    }

    .mat-expansion-panel-header-description .mat-expansion-indicator::after {
        color: #fff !important;
    }
}

@media (max-width: 575px) {
    .icon_wrap .mat-icon {
        font-size: 50px;
        color: #e4af18;
        right: 50px;
        top: -105px;
        position: absolute !important;
        display: block !important;
    }

}

agm-map.sebm-google-map-container {
    width: 100%;
    height: 546px;
}
.agm-map-container-inner {
    border-radius: 24px;
}
.certificate {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .mat-icon {
        position: relative;
        display: block;
        color: #ffd740;
        cursor: pointer;
        top: 22px;
    }
}

.automaten_stellplatz{
    font-size: 13px;
    line-height: 19.5px;
    background-color: #56769C;
    padding: 2px 4px;
    border-radius: 6px;
    margin-top: 2px;
}
.wettbuero{
    font-size: 13px;
    line-height: 19.5px;
    background-color: #FF3333;
    padding: 2px 4px;
    border-radius: 6px;
    margin-top: 2px;
}
.spielhalle{
    font-size: 13px;
    line-height: 19.5px;
    background-color: #F47500;
    padding: 2px 4px;
    border-radius: 6px;
    margin-top: 2px;
}

.profil_button{
    background-color: #000 !important;
    color: #fff !important;
    border: 1px solid #fff !important;
    border-radius: 64px !important;
    padding: 8px 16px !important;
    margin-top: 15px !important;
}



@media (max-width: 767px) {

    .home_container {
        min-height: unset;
    }

    .footer {
        margin-top: 40px;
    }

    .detail_container {
        .detail_info {
            margin-bottom: 32px;

            p {
                font-size: 15px;
            }
        }
    }

    .hall_list {
        margin-bottom: 12px;
        border: none;
        p {
            margin: 3px 0 0 0;
        }
        .tags {
            top: 15px;
            position: relative;
        }
    }

    .hall_list:last-child {
        border: none;
    }

    .certificate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }

    .hidden_mobile{ 
        display: none !important;
    }

    .hidden_desktop{
        display: block;
        margin: 0 auto;
        width: 100%;
        height: auto;
        margin-top: 63px;
    }

    .home_container{

        h1  {
            font-size: 32px;
            line-height: 35.2px;
        }

        .home_middle_wrap {
            display: block;
        }
    }

    .search_nav_container {
        position: absolute;
        width: 100%;
        z-index: 1;
        top: 45px;
        height: auto;
        padding: 0px 15px;
        margin: 0 auto;
        left: 0;
    }

    .header_search {
        left: 0;
        z-index: 1;
        width: 100%;
        height: auto;
        padding: 0px 15px 15px 15px;

        input{
            width: 100%;
            margin-left: 0px;
            line-height: 15px;
            border: none;
            color: #6e6e6e;
            font-size: 14px;
            border-radius: 0;
            margin-right: 5px;
        }

        p{
            font-size: 14px;
            text-align: center;
        }
    }

}

.center_button{
    display: flex !important;
    margin: 0 auto !important;
}

.register_container{
    margin-top: 30px;

    input {
        width: 100%;
        margin-left: 0px;
        line-height: 15px;
        border: none;
        color: #6e6e6e;
        font-size: 14px;
        border-radius: 0;
        margin-right: 5px;
        padding-left: 20px;

    }
}

.successSnackBar{
    background: #00ff00;
    color: #000;
}

.errorSnackBar{
    background: #ff0000;
    color: #fff;
}

.mat-error {
    display: block;
    text-align: center;
    margin-top: 16px;
}

.operator_link{
    margin-top: 20px;
    .mat-icon{
        z-index: 1
    }
}

.infobox_wrap {
    h1, p{
        color: #000
    }
}

.infobox-icon{
    color: #fff;
    position: relative;
    left: 15px;
    top: 5px;
}

.select-instant-create {
    padding: 15px;
    background: white;
    border-radius: 0px 0px 4px 4px;
    margin-top: -5px;
    border-top: none;
}

.align_right {
    float: right;
}

.bookmaker_icon{
    position: relative;
}

//static pages
.grey-bg{
    color: #fff;
}

.pageDivider {
    margin: 40px auto;
    width: 98%;
    height: 1px;
    border-bottom: 2px dotted #edcb78;
    position: relative;

    .divimgholder {
        position: absolute;
        width: 50px;
        left: 50%;
        margin-left: -25px;
        margin-top: -15px;
        text-align: center;
    }

    img {
        vertical-align: middle;
    }
}

.text_left {
    p{
        text-align: left;
    }
}

.agb_wrap{
   max-width: 650px;
   width: 100%; 
   margin: 0 auto;
   h3 {
       color: #fff;
       text-align: center;
   }
}

.nutzungsbedingungen_wrap {
    max-width: 12000px;
    width: 100%;
    margin: 0 auto;
    ul li{
        color: #fff;
        list-style: numeric;
    }

    p {
        font-weight: bold;
    }
}

.impressum_wrap {
    max-width: 12000px;
    width: 100%;
    margin: 0 auto;
    h4 {
        color: #fff;
        text-align: center;
    }
}

.contact_li{
    color: #fff;
}